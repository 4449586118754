body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	color: #eee;
	overscroll-behavior-y: contain;
	background-color: transparent;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h2 {
	margin-top: 0px;
}

.poster .image {
	width: calc(25vw - 80px);
}
@media (max-width: 1300px) {
	.poster .image {
		min-width: calc(50vw - 80px);
	}
}
@media (max-width: 800px) {
	.poster .image {
		min-width: calc(100vw - 80px);
	}
}

.poster {
	display: grid;
	justify-content: center;
	align-content: center;
}
