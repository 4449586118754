#page {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior: none;
}

a {
	color: #ddd;
}

.pull-down-header,
.pull-down-header * {
	background-color: rgba(0, 0, 0, 0) !important;
	border-left: none !important;
}
.pull-down-content--icon {
	display: none;
}
