:root {
	--background-padding: 40px;
}

#background {
	position: absolute;
	top: calc(var(--background-padding) / -2);
	left: calc(var(--background-padding) / -2);
	width: calc(100vw + var(--background-padding));
	height: calc(100vh + var(--background-padding));
	filter: blur(4px) brightness(35%);

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: -1;
}
